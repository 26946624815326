.adminHeaderContainer{
  display: flex;
  flex-direction: row;
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 5;
  box-shadow: 1px 0px 4px grey;
  top: 0px;
}

.rollyLogo {
  height: 50px;
}

.headerLogo {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  border: none;
  margin-top: 5px;
  margin-bottom: 15px;
}

.headerLogo {
  width: 100% !important;
}

.adminHeaderLogo {
  width: 170px;
  text-transform: uppercase;
  font-size: 25px;
  display: flex;
  justify-content: center;
  color: black;
  align-items: center;
  color: white;
  background-color: rgb(0,0,0);
  font-weight: 750;
  padding-left: 50px;
}

.headerLogo a {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.logo {
}

.adminHeaderContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: rgb(0,0,0);
  color: blue;
  padding-right: 150px;
}

.headerUsernameContainer {
  z-index: 10;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0,0,0);
  color: white;
  padding-right: 30px;
}

.headerUsernameContainerIcon {
  margin-top: 8px;
}

.headerUsernameContainerName{
  margin-left: 8px;
}
